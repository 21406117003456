//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { isValidCif, isValidDni, isValidNie } from 'nif-dni-nie-cif-validation';

import copy from 'copy-to-clipboard';
import IBAN from 'iban';
import ViuLabel from 'viu/components/viu-label/viu-label.vue';
import ViuSwitch from 'viu/components/viu-switch/viu-switch.vue';
import ViuButton from 'viu/components/viu-button/viu-button.vue';
import StepLayout from '../step-layout.vue';
import Button from '../../components/button.vue';
import CustomRate from '../../components/custom-rate.vue';
import BottomSheetCustomRate from '../../components/bottom-sheet-custom-rate.vue';
import CustomOfferDisclaimer from '../../components/custom-offer-disclaimer.vue';
import stepMixin from '../../lib/step-mixin.js';
import {
  getFormInitialData,
  getLocalityByProvince,
  getRandomPerson,
  getRandomDNI,
  getRandomCIF,
  getBicSwiftByIban,
  uploadFile,
  getProvinciaByCode,
  getMunicipio,
  getTipoViaByName
} from '../../lib/api.js';
import FormErrors from '../../lib/form-errors.js';

// Componentes formulario
import FormSelect from '../../components/form/form-select.vue';
import FormInput from '../../components/form/form-input.vue';
import FormDesktopBox from '../../components/form/form-desktop-box.vue';
import FormMobileBox from '../../components/form/form-mobile-box.vue';
import FormInputFile from '../../components/form/form-input-file.vue';
import FormSwitch from '../../components/form/form-switch.vue';
import FormTitleSection from '../../components/form/form-title-section.vue';
import FormInputTitle from '../../components/form/form-input-title.vue';
import FormCheckboxText from '../../components/form/form-checkbox-text.vue';
import FormRadio from '../../components/form/form-radio.vue';

import * as apis from '$network/api.js';

function clone(obj) {
  return JSON.parse(JSON.stringify(obj));
}

const extractOptions = (list, labelKey, valueKey) => {
  return list.map(i => ({ label: i[labelKey], value: i[valueKey] }));
};

const sanetizeDate = date =>
  date
    .split('-')
    .map(Number)
    .map(n => (n < 10 ? `0${n}` : n))
    .join('-');

export default {
  components: {
    CustomRate,
    ViuLabel,
    ViuSwitch,
    ViuButton,
    FormSelect,
    FormInputTitle,
    FormMobileBox,
    FormDesktopBox,
    FormInput,
    FormSwitch,
    FormInputFile,
    FormTitleSection,
    StepLayout,
    Button,
    BottomSheetCustomRate,
    CustomOfferDisclaimer,
    FormCheckboxText,
    FormRadio
  },
  mixins: [stepMixin],
  props: {
    houseType: String,
    cups: Object,
    formExternalData: Object,
    contractData: Object,
    contractCreated: Object,
    leadId: String,
    offers: Object,
    allSteps: Object
  },
  data: () => ({
    bateriaVirtual: {},
    sendingBateria: false,
    optionsPaises: [],
    buttonState: '',
    localityToCopy: null,
    documentTypeTitle: {
      owner: '',
      billingContact: '',
      billingOther: '',
      contact: ''
    },
    scoringActivo: false,
    scoringAprove: false,
    buttonMessage: 'Sin Scoring',
    marketerName: '',
    triedContinue: false,
    downloading: false,
    formErrors: [],
    provinces: [],
    localities: [],
    ownerLocalities: [],
    viaTypes: [],
    cnaes: [],
    userDocumentTypes: [],
    loading: false,
    loadingLocalities: false,
    loadingOwnerLocalities: false,
    selectedOffer: null,
    optionsTipoGestion: [
      { text: 'Crear nueva Batería Virtual', value: 'nueva' },
      { text: 'Importar Batería Virtual existente', value: 'importar' }
    ],
    optionsModoBateria: [
      { value: 'U', text: 'Batería Única' },
      { value: 'M', text: 'Multibatería' }
    ],
    optionsModoActivacion: [
      { value: 'autoconsumo', label: 'En activar Autoconsumo' },
      { value: 'inminente', label: 'Inmediatamente' }
    ],
    bateriaVirtualValidada: false,
    form: {
      specificBussiness: {
        socialReason: '',
        pics: {
          notarialBase64: '',
          cifBase64: ''
        },
        cnae: ''
      },
      bateriaVirtual: {
        tipo_bateria: '',
        modo_bateria: '',
        modo_activacion: '',
        codigo_bateria: '',
        identificador_asociado: '',
        clasificacion: ''
      },
      owner: {
        personType: '',
        documentType: '',
        name: '',
        lastName: '',
        secondLastName: '',
        document: '',
        phone: '',
        email: '',
        pais_procedencia: '',
        confirmEmail: '',
        documentPics: {
          base64Front: '',
          base64Back: '',
          bill: ''
        },
        address: {
          postalCode: '',
          locality: '',
          province: '',
          viaType: '',
          line1: '',
          number: '',
          streetPortal: '',
          stairs: '',
          floor: '',
          door: ''
        }
      },
      differentOwnerAddress: false,
      contactIsNotOwner: false,
      haveVirtualBattery: false,
      contact: {
        documentType: '',
        document: '',
        name: '',
        lastName: '',
        secondLastName: '',
        email: '',
        confirmEmail: '',
        phone: ''
      },
      address: {
        postalCode: '',
        locality: '',
        province: '',
        viaType: '',
        line1: '',
        number: '',
        streetPortal: '',
        stairs: '',
        floor: '',
        door: ''
      },
      documents: {
        virtualBattery: {
          type: 'anexo',
          fileName: ''
        }
      },
      billing: {
        iban: '',
        bicSwiftCode: '',
        /**
         * Tipo de billingOwner:
         * - owner: El titular (form.owner)
         * - contact: El titular real (form.contact)
         * - other: Otro titular (form.billing.owner)
         */
        billingOwner: 'owner',
        owner: {
          documentType: '',
          name: '',
          lastName: '',
          secondLastName: '',
          document: ''
        }
      },
      confirmations: {
        commercialComms: false,
        hiringTermsPolicyPrivacy: false,
        marketerStartServiceBeforeWithdrawal: false
      }
    }
  }),
  computed: {
    existsIncidence() {
      return this.contractCreated && this.contractCreated.incidence.id;
    },
    // Crea un objeto que contiene los errores segun el input.
    // Se usa para mostrar los errores inline.
    invalidTexts() {
      if (!this.triedContinue) return {};

      const { formErrors: errors } = this;
      const mapErrorInput = {
        [FormErrors.PAIS]: 'pais_procedencia',
        [FormErrors.DOCUMENT_TYPE_REQUIRED]: 'identifierType',
        [FormErrors.DOCUMENT_REQUIRED]: 'identifier',
        [FormErrors.DOCUMENT_INVALID]: 'identifier',
        [FormErrors.NIE_INVALID]: 'identifier',
        [FormErrors.NAME_REQUIRED]: 'name',
        [FormErrors.LASTNAME_REQUIRED]: 'lastName',
        [FormErrors.SECOND_LASTNAME_REQUIRED]: 'secondLastName',
        [FormErrors.PHONE_REQUIRED]: 'phone',
        [FormErrors.EMAIL_REQUIRED]: 'email',
        [FormErrors.EMAIL_INVALID]: 'email',
        [FormErrors.EMAIL_NOT_EQUAL]: 'email',

        [FormErrors.CONTACT_DOCUMENT_TYPE_REQUIRED]: 'contactIdentifierType',
        [FormErrors.CONTACT_DOCUMENT_REQUIRED]: 'contactIdentifier',
        [FormErrors.CONTACT_DOCUMENT_DNI_INVALID]: 'contactIdentifier',
        [FormErrors.CONTACT_DOCUMENT_NIE_INVALID]: 'contactIdentifier',
        [FormErrors.CONTACT_NAME_REQUIRED]: 'contactName',
        [FormErrors.CONTACT_LASTNAME_REQUIRED]: 'contactLastname',
        [FormErrors.CONTACT_SECOND_LASTNAME_REQUIRED]: 'contactSecondLastname',
        [FormErrors.CONTACT_PHONE_REQUIRED]: 'contactPhone',
        [FormErrors.CONTACT_EMAIL_REQUIRED]: 'contactEmail',
        [FormErrors.CONTACT_EMAIL_NOT_EQUAL]: 'contactEmail',

        [FormErrors.POSTALCODE_REQUIRED]: 'postalCode',
        [FormErrors.PROVINCE_REQUIRED]: 'province',
        [FormErrors.LOCALITY_REQUIRED]: 'locality',
        [FormErrors.VIA_TYPE_REQUIRED]: 'viaType',
        [FormErrors.ADDRESS_REQUIRED]: 'line1',
        [FormErrors.ADDRESS_NUMBER_REQUIRED]: 'number',
        [FormErrors.IBAN_REQUIRED]: 'iban',
        [FormErrors.IBAN_INVALID]: 'iban',
        [FormErrors.BICSWIFT_REQUIRED]: 'bicSwiftCode',
        [FormErrors.DOCUMENT_PIC_FRONT_REQUIRED]: 'frontPic',
        [FormErrors.DOCUMENT_VIRTUAL_BATTERY_REQUIRED]: 'virtualBattery',
        [FormErrors.DOCUMENT_PIC_BACK_REQUIRED]: 'backPic',
        [FormErrors.COMMERCIAL_COMMS_REQUIRED]: 'commercialComms',
        [FormErrors.TEMS_PRIVACY_REQUIRED]: 'marketerStartServiceBeforeWithdrawal',
        [FormErrors.MARKETER_START_SERVICE_REQUIRED(this.marketerName)]: 'hiringTermsPolicyPrivacy',

        [FormErrors.BILLING_OWNER_DOCUMENT_TYPE_REQUIRED]: 'billingOwnerDocumentType',
        [FormErrors.BILLING_OWNER_DOCUMENT_REQUIRED]: 'billingOwnerDocument',
        [FormErrors.BILLING_OWNER_DOCUMENT_INVALID]: 'billingOwnerDocument',
        [FormErrors.BILLING_OWNER_NIE_INVALID]: 'billingOwnerDocument',
        [FormErrors.BILLING_OWNER_NAME_REQUIRED]: 'billingOwnerName',
        [FormErrors.BILLING_OWNER_LASTNAME_REQUIRED]: 'billingOwnerLastname',
        [FormErrors.BILLING_OWNER_SECOND_LASTNAME_REQUIRED]: 'billingOwnerSecondLastname',
        [FormErrors.OWNER_ADDRESS_POSTALCODE_REQUIRED]: 'ownerPostalCode',
        [FormErrors.OWNER_ADDRESS_PROVINCE_REQUIRED]: 'ownerProvince',
        [FormErrors.OWNER_ADDRESS_LOCALITY_REQUIRED]: 'ownerLocality',
        [FormErrors.OWNER_ADDRESS_VIA_TYPE_REQUIRED]: 'ownerViaType',
        [FormErrors.OWNER_ADDRESS_ADDRESS_REQUIRED]: 'ownerLine1',
        [FormErrors.OWNER_ADDRESS_ADDRESS_NUMBER_REQUIRED]: 'ownerNumber',
        [FormErrors.BUSSINESS_DOCUMENT_TYPE_INVALID]: 'bussinessDocumentTypeInvalid',
        [FormErrors.BUSSINESS_IDENTIFICATOR_INVALID]: 'bussinessIdentificatorInvalid',
        [FormErrors.BUSSINESS_PERSONA_TYPE]: 'bussinessPersonaType',
        [FormErrors.BUSSINESS_SOCIAL_REASON]: 'bussinessSocialReason',
        [FormErrors.BUSSINESS_PHONE]: 'bussinessPhone',
        [FormErrors.BUSSINESS_EMAIL]: 'bussinessEmail',
        [FormErrors.BUSSINESS_EMAIL_CONFIRMATION]: 'bussinessEmailConfirmation',
        [FormErrors.BUSSINESS_PIC_NOTARIAL]: 'bussinessPicNotarial',
        [FormErrors.BUSSINESS_PIC_CIF]: 'bussinessPicCif',
        [FormErrors.BUSSINESS_SIGNATURE_NAME]: 'bussinessSignatureName',
        [FormErrors.BUSSINESS_SIGNATURE_LASTNAME]: 'bussinessSignatureLastname',
        [FormErrors.BUSSINESS_SIGNATURE_SECONDLASTNAME]: 'bussinessSignatureSecondlastname',
        [FormErrors.BUSSINESS_SIGNATURE_DNI]: 'bussinessSignatureDni',
        [FormErrors.BUSSINESS_SIGNATURE_PHONE]: 'bussinessSignaturePhone',
        [FormErrors.BUSSINESS_SIGNATURE_EMAIL]: 'bussinessSignatureEmail',
        [FormErrors.BUSSINESS_SIGNATURE_EMAIL_CONFIRMATION]: 'bussinessSignatureEmailConfirmation',
        [FormErrors.BUSSINESS_CNAE]: 'cnaes',
        [FormErrors.DOCUMENT_GESTION_BATERIA]: 'modo_activacion',
        [FormErrors.CODIGO_BATERIA_VIRTUAL]: 'codigo_bateria_virtual',
        [FormErrors.IDENTIFICADOR_BATERIA_VIRTUAL]: 'identificador_bateria_virtual'
      };
      const result = {};

      errors.forEach(error => {
        if (mapErrorInput[error]) result[mapErrorInput[error]] = error;
      });

      return result;
    },
    startDate() {
      return sanetizeDate(this.formExternalData.datosEstudio.fechaInicio);
    },
    endDate() {
      return sanetizeDate(this.formExternalData.datosEstudio.fechaFin);
    },
    offerInfo() {
      return this.formExternalData.offerSelected;
    },
    offersList() {
      return this.offers?.external?.ofertas || [];
    },
    hiredPower() {
      const list = this.formExternalData?.datosEstudio?.potenciaContratada || {};
      const keys = Object.keys(list);

      return keys
        .reduce((acc, key) => {
          const value = list[key];

          if (Number(value) === 0) return acc;

          acc.push(`${key.toUpperCase()}: ${value / 1000} kW`);

          return acc;
        }, [])
        .join(', ');
    },
    continueDisabled() {
      const { form } = this;

      return (
        !form.owner.document ||
        !form.owner.documentPics.base64Front ||
        !form.owner.documentPics.base64Back ||
        !form.owner.name ||
        !form.owner.lastName ||
        !form.owner.secondLastName ||
        !form.owner.phone ||
        !form.owner.email ||
        !form.owner.confirmEmail ||
        !form.address.postalCode ||
        !form.address.locality ||
        !form.address.province ||
        !form.address.viaType ||
        !form.address.line1 ||
        !form.address.streetPortal ||
        !form.address.stairs
      );
    },
    localityState() {
      if (!this.form.address.province) return 'NOT_INITIED';

      if (this.loadingLocalities) return 'LOADING';

      return 'LOADED';
    },
    localityOwnerState() {
      if (!this.form.owner.address.province) return 'NOT_INITIED';

      if (this.loadingOwnerLocalities) return 'LOADING';

      return 'LOADED';
    },
    userDocumentTypesComputed() {
      const { houseType, userDocumentTypes } = this;

      // Los tipos que se muestran si es tipo empresa
      if (houseType === 'business') {
        return userDocumentTypes.filter(d => ['CIF'].includes(d.label));
      }

      return userDocumentTypes.filter(d => !['CIF'].includes(d.label));
    },
    contactDocumentTypesComputed() {
      return this.userDocumentTypes.filter(d => !['CIF'].includes(d.label));
    }
  },
  watch: {
    'form.owner.document': {
      handler() {
        if (this.form.owner.document !== this.allSteps[4].inputData.datosTitular.identificador) {
          this.buttonMessage = 'No calculado';
          this.buttonState = '';
        }

        if (
          this.scoringActivo === true &&
          this.form.owner.document === this.allSteps[4].inputData.datosTitular.identificador
        ) {
          this.calcularScoring();
        }
      }
    },
    'form.address.province': async function formAdressProvinceWatcher(provinceId) {
      this.localities = [];
      this.form.address.locality = '';
      this.loadingLocalities = true;

      if (!provinceId) return;

      const response = await getLocalityByProvince(provinceId);
      this.loadingLocalities = false;
      this.localities = extractOptions(response.data.municipios, 'nombre', 'id');
      this.$nextTick(() => {
        if (this.localityToCopy) {
          this.form.address.locality = this.localityToCopy;
          this.localityToCopy = '';
        }
      });
    },
    'form.owner.address.province': async function formOwnerAdressProvinceWatcher(provinceId) {
      this.ownerLocalities = [];
      this.form.owner.address.locality = '';
      this.loadingOwnerLocalities = true;

      if (!provinceId) return;

      const response = await getLocalityByProvince(provinceId);
      this.loadingOwnerLocalities = false;
      this.ownerLocalities = extractOptions(response.data.municipios, 'nombre', 'id');
      this.$nextTick(() => {
        if (this.localityToCopy) {
          this.form.owner.address.locality = this.localityToCopy;
          this.localityToCopy = '';
        }
      });
    },
    form: {
      handler() {
        if (this.formErrors.length !== 0) {
          this.formErrors = this.getFormErrors();
        }
      },
      deep: true
    },
    // Si se desactiva el switch de "El titular es otra persona", reiniciar los datos introducidos
    'form.contactIsNotOwner': function formcontactIsNotOwner(value) {
      if (!value) {
        this.form.contact.name = '';
        this.form.contact.lastName = '';
        this.form.contact.secondLastName = '';
        this.form.contact.email = '';
        this.form.contact.confirmEmail = '';
        this.form.contact.phone = '';

        if (this.form.billing.billingOwner === 'contact') {
          this.form.billing.billingOwner = 'owner';
        }
      }
    },
    // Si se desactiva el input[radio] de "Otra persona" en facturacion, reiniciar los datos introducidos
    'form.billing.billingOwner': function formBillingBillingOwner(value) {
      if (value !== 'other') {
        this.form.billing.owner.documentType = '';
        this.form.billing.owner.name = '';
        this.form.billing.owner.lastName = '';
        this.form.billing.owner.secondLastName = '';
        this.form.billing.owner.document = '';
      }
    }
  },
  async created() {
    const response = await getFormInitialData();
    const {
      provincias: provinces,
      tipo_vias: viaTypes,
      user_document_types: userDocumentTypes,
      marketer,
      cnaes
    } = response.data;

    this.optionsPaises = response.data.paises.map(pais => ({
      label: pais.descripcion,
      value: pais.codigo
    }));

    this.marketerName = marketer;
    this.provinces = extractOptions(provinces, 'nombre', 'id');
    this.viaTypes = extractOptions(viaTypes, 'descripcion', 'id');
    this.userDocumentTypes = extractOptions(
      userDocumentTypes.reduce(this.modifyUserOptions, []),
      'descripcion',
      'id'
    );
    this.cnaes = extractOptions(
      cnaes.map(cnae => {
        const newCnae = cnae;
        newCnae.descripcion = `${cnae.codigo} - ${cnae.descripcion}`;
        return cnae;
      }),
      'descripcion',
      'id'
    );

    this.loading = false;

    this.getBateriaVirtual();

    if (this.allSteps[6].finished === true) this.loadContractDataIfExists();

    if (this.houseType === 'business') {
      this.form.specificBussiness.cnae =
        this.checkUndefined(this.allSteps[4].externalData.idCnae) !== ''
          ? Number(this.allSteps[4].externalData.idCnae)
          : '';
    }
    this.form.owner.documentType =
      this.checkUndefined(this.allSteps[4].inputData.datosTitular.tipoIdentificador) !== ''
        ? Number(this.allSteps[4].inputData.datosTitular.tipoIdentificador)
        : '';

    this.form.owner.document = this.checkUndefined(
      this.allSteps[4].inputData.datosTitular.identificador
    );

    this.form.owner.name = this.checkUndefined(this.allSteps[4].inputData.datosTitular.nombre);

    this.form.owner.lastName = this.checkUndefined(
      this.allSteps[4].inputData.datosTitular.primerApellido
    );

    this.form.owner.secondLastName = this.checkUndefined(
      this.allSteps[4].inputData.datosTitular.segundoApellido
    );

    this.form.specificBussiness.socialReason = this.checkUndefined(
      this.allSteps[4].inputData.datosTitular.razonSocial
    );

    if (this.allSteps[1].inputData.house_type === 'business') {
      apis.scoringActivo().then(responseScoring => {
        this.scoringActivo = responseScoring.data;
        if (this.scoringActivo === true) {
          this.calcularScoring();
        }
      });
    }
  },
  methods: {
    isInvalidEmail(email) {
      return !/^[\w|\+|\.|\-]+([\.-]?\w+?[\++]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,15})+$/.test(email);
    },
    eliminarBateriaVirtual() {
      this.bateriaVirtualValidada = false;
      this.form.bateriaVirtual.codigo_bateria = '';
      this.form.bateriaVirtual.identificador_asociado = '';
    },
    importBateria() {
      if (this.sendingBateria) return;

      if (
        this.form.bateriaVirtual.codigo_bateria === '' ||
        this.form.bateriaVirtual.identificador_asociado === '' ||
        this.form.owner.document === ''
      ) {
        this.$notify.error(
          'Debe rellenar los campos de código de batería, identificador asociado y el identificador del titular'
        );
        return;
      }

      this.sendingBateria = true;

      apis
        .existBateriaVirtual(
          this.form.bateriaVirtual.codigo_bateria,
          this.form.bateriaVirtual.identificador_asociado,
          this.form.owner.document
        )
        .then(response => {
          if (response.status === 200) {
            if (response.data.virtual_battery.existe) {
              this.bateriaVirtualValidada = true;
              this.form.bateriaVirtual.clasificacion =
                response.data.virtual_battery.tipoBateria === 'M' ? 'Multibateria' : 'Unica';
            }

            if (!response.data.virtual_battery.existe) {
              this.$notify.error('No se ha encontrado la batería virtual');
            }

            this.sendingBateria = false;
          }
        })
        .catch(() => {
          this.$notify.error(
            'Error al comprobar la batería virtual, consulte con el administrador.'
          );
          this.sendingBateria = false;
        });
    },
    getBateriaVirtual() {
      this.bateriaVirtual = this.allSteps[3].externalData.tarifas.filter(
        tarifa => tarifa.id === this.allSteps[5].inputData.offerSelected.id
      )[0].bateriaVirtual;

      if (this.bateriaVirtual.modoBateria === 'ambas') this.form.bateriaVirtual.modo_bateria = 'U';
      if (this.bateriaVirtual.modoBateria !== 'ambas')
        this.form.bateriaVirtual.modo_bateria = this.bateriaVirtual.modoBateria;

      if (this.bateriaVirtual.modoActivacionBateria === 'ambas')
        this.form.bateriaVirtual.modo_activacion = 'autoconsumo';
      if (this.bateriaVirtual.modoActivacionBateria !== 'ambas')
        this.form.bateriaVirtual.modo_activacion = this.bateriaVirtual.modoActivacionBateria;
    },
    checkUndefined(texto) {
      if (texto !== undefined && texto !== null) return texto;
      return '';
    },
    async onIbanChange() {
      try {
        const { iban } = this.form.billing;
        const ibanLength = iban.length;
        const rightLength = ibanLength >= 16 && ibanLength <= 34; // Longitud basada en la excepción lanzada por la librería https://github.com/xafardero/generate-iban/blob/master/src/Iban.php

        if (rightLength) {
          const response = await getBicSwiftByIban(iban);
          const { bicSwift } = response.data;
          this.form.billing.bicSwiftCode = bicSwift;
        }
      } catch (error) {
        // console.log(error);
      }
    },
    removeDocumentVirtualBattery() {
      this.form.documents.virtualBattery.fileName = '';
    },
    removeOwnerFrontPic() {
      this.form.owner.documentPics.base64Front = '';
    },
    removeOwnerBackPic() {
      this.form.owner.documentPics.base64Back = '';
    },
    removeCifEmpresaPic() {
      this.form.specificBussiness.pics.cifBase64 = '';
    },
    removeNotarialPic() {
      this.form.specificBussiness.pics.notarialBase64 = '';
    },
    removeBill() {
      this.form.owner.documentPics.bill = '';
    },
    async loadDummyData() {
      const response = await getRandomPerson();
      const randomDNIResponse = await getRandomDNI();
      const randomCIFIResponse = await getRandomCIF();

      const human = response.data[0];
      const dni = randomDNIResponse.data[0];
      const dni2 = randomDNIResponse.data[1];

      const cif = randomCIFIResponse.data[0];

      this.form.billing.bicSwiftCode = human.bic;
      this.form.billing.billingOwner = human.fullname;
      this.form.billing.iban = human.iban;
      this.form.billing.billingOwner = 'owner';
      this.form.confirmations.commercialComms = true;
      this.form.confirmations.hiringTermsPolicyPrivacy = true;
      this.form.confirmations.marketerStartServiceBeforeWithdrawal = true;
      this.form.contactIsNotOwner = false;
      this.form.differentOwnerAddress = false;

      this.form.owner.email = 'ivanhg@me.com';
      this.form.owner.confirmEmail = 'ivanhg@me.com';
      this.form.owner.documentType = 4;

      if (this.houseType === 'business') {
        this.form.specificBussiness.socialReason = 'Federico SL';
        this.form.owner.document = cif;
      }

      if (this.houseType !== 'business') {
        this.form.owner.document = dni;
        this.form.owner.name = human.name;
        this.form.owner.lastName = human.surname;
        this.form.owner.secondLastName = human.surnname2;
      }

      this.form.contact.name = human.name;
      this.form.contact.lastName = human.surname;
      this.form.contact.secondLastName = human.surnname2;
      this.form.contact.documentType = 4;
      this.form.contact.document = dni2;
      this.form.contact.phone = '637788288';
      this.form.contact.email = 'ivanhg@me.com';
      this.form.contact.confirmEmail = 'ivanhg@me.com';

      this.form.owner.phone = '637788288';
      this.form.owner.address.door = '1';
      this.form.owner.address.floor = '1';
      this.form.owner.address.line1 = human.address;
      this.form.owner.address.number = 34;
      this.form.owner.address.postalCode = '03206';
      this.form.owner.address.province = 2;
      this.form.owner.address.stairs = '1';
      this.form.owner.address.streetPortal = '1';
      this.form.owner.address.viaType = 18;

      setTimeout(() => {
        this.form.owner.address.locality = 203;
      }, 200);
    },
    async loadDataFromInvoice() {
      this.form.billing.billingOwner = 'owner';

      if (this.houseType === 'business') {
        this.form.specificBussiness.socialReason =
          this.cups?.invoice?.informacionContratacion?.titularFactura?.fullName ?? '';
      }

      if (this.houseType !== 'business') {
        this.form.owner.name =
          this.cups?.invoice?.informacionContratacion?.titularFactura?.name ?? '';
        const surname = this.cups?.invoice?.informacionContratacion?.titularFactura?.surname ?? '';

        const surnameSplitted = surname.split(' ');

        if (surnameSplitted.length > 1) {
          this.form.owner.lastName = surnameSplitted[0] ?? '';
          this.form.owner.secondLastName = surnameSplitted[1] ?? '';
        }
      }

      this.form.contactIsNotOwner = false;
      this.form.differentOwnerAddress = false;

      this.form.owner.documentType = 4;

      const nif = this.cups.invoice?.informacionContratacion?.nif ?? '';
      const addressLetter =
        this.cups.invoice?.informacionContratacion?.direccionSuministro?.letter ?? '';
      const addressFloor =
        this.cups.invoice?.informacionContratacion?.direccionSuministro?.floor ?? '';
      const addressStreet =
        this.cups.invoice?.informacionContratacion?.direccionSuministro?.street ?? '';
      const addressNumber =
        this.cups.invoice?.informacionContratacion?.direccionSuministro?.number ?? '';
      const addressPortal =
        this.cups.invoice?.informacionContratacion?.direccionSuministro?.portal ?? '';
      const addressStairs =
        this.cups.invoice?.informacionContratacion?.direccionSuministro?.stairs ?? '';
      const addressPostal =
        this.cups.invoice?.informacionContratacion?.direccionSuministro?.postalCode ?? '';
      const addressType =
        this.cups.invoice?.informacionContratacion?.direccionSuministro?.address_type ?? '';
      let addressMunicipio =
        this.cups.invoice?.informacionContratacion?.direccionSuministro?.city ?? '';

      this.form.owner.document = nif;
      this.form.owner.address.door = addressLetter;
      this.form.owner.address.floor = addressFloor;
      this.form.owner.address.line1 = addressStreet;
      this.form.owner.address.number = addressNumber;
      this.form.owner.address.stairs = addressStairs;
      this.form.owner.address.streetPortal = addressPortal;
      this.form.owner.address.postalCode = addressPostal;

      if (addressType !== '') {
        const tipoViaResponse = await getTipoViaByName(
          this.cups.invoice.informacionContratacion.direccionSuministro.address_type
        );

        if (tipoViaResponse.data.tipoVia !== null) {
          this.form.owner.address.viaType = tipoViaResponse.data.tipoVia.id;
        }
      }

      if (addressPostal.length >= 2) {
        const provinciaCode = addressPostal.substring(0, 2);

        const provinciaResponse = await getProvinciaByCode(provinciaCode);

        this.form.owner.address.province = provinciaResponse.data.provincia.id;

        if (addressMunicipio !== '') {
          const containSlash = addressMunicipio.includes('/');

          if (containSlash) {
            const [, municipio] = addressMunicipio.split('/');
            addressMunicipio = municipio;
          }

          const municipioResponse = await getMunicipio(provinciaCode, addressMunicipio);

          if (municipioResponse.data.municipio !== null) {
            setTimeout(() => {
              this.form.owner.address.locality = municipioResponse.data.municipio.id;
            }, 200);
          }
        }
      }
    },
    isObject(value) {
      return value && typeof value === 'object' && value.constructor === Object;
    },
    async loadContractDataIfExists() {
      // if (this.contractData.contractInfo.owner.document === null) {
      //   this.loadDummyData();
      //   return;
      // }

      if (
        this.contractData.contractInfo.owner.document === null &&
        this.isObject(this.cups.invoice)
      ) {
        await this.loadDataFromInvoice();
        return;
      }

      const contractInfo = JSON.parse(JSON.stringify(this.contractData.contractInfo));
      this.$nextTick(() => {
        if (contractInfo.owner) this.form.owner = contractInfo.owner;

        if (contractInfo?.documents) this.form.documents = contractInfo.documents;

        if (contractInfo?.haveVirtualBattery) {
          this.form.haveVirtualBattery = contractInfo.haveVirtualBattery;
          this.form.bateriaVirtual.tipo_bateria = contractInfo.bateriaVirtual.tipoBateria;
          this.form.bateriaVirtual.modo_bateria = contractInfo.bateriaVirtual.modoBateria;
          this.form.bateriaVirtual.modo_activacion = contractInfo.bateriaVirtual.modoActivacion;
          this.form.bateriaVirtual.codigo_bateria = contractInfo.bateriaVirtual.codigoBateria;
          this.form.bateriaVirtual.identificador_asociado =
            contractInfo.bateriaVirtual.identifiacdorAsociado;
          this.form.bateriaVirtual.clasificacion = contractInfo.bateriaVirtual.clasificacion;
        }

        if (contractInfo.address) this.form.address = contractInfo.address;

        if (contractInfo.billing) this.form.billing = contractInfo.billing;

        if (contractInfo.billing.billingOwner === null) {
          this.form.billing.billingOwner = 'owner';
        }

        if (contractInfo.confirmations) {
          this.form.confirmations.commercialComms =
            contractInfo.confirmations?.commercialComms ?? false;
          this.form.confirmations.hiringTermsPolicyPrivacy =
            contractInfo.confirmations?.hiringTermsPolicyPrivacy ?? false;
          this.form.confirmations.marketerStartServiceBeforeWithdrawal =
            contractInfo.confirmations?.marketerStartServiceBeforeWithdrawal ?? false;
        }

        if (contractInfo.contact) this.form.contact = contractInfo.contact;

        if (contractInfo.contactIsNotOwner)
          this.form.contactIsNotOwner = contractInfo.contactIsNotOwner;

        if (contractInfo.differentOwnerAddress)
          this.form.differentOwnerAddress = contractInfo.differentOwnerAddress;

        if (contractInfo.specificBussiness)
          this.form.specificBussiness = contractInfo.specificBussiness;

        setTimeout(() => {
          if (
            this.contractData.contractInfo.specificBussiness.cnae !== '' &&
            this.contractData.contractInfo.specificBussiness.cnae !== null
          ) {
            this.form.specificBussiness.cnae = this.contractData.contractInfo.specificBussiness.cnae;
          }

          if (
            this.contractData.contractInfo.owner.address.locality &&
            this.form.owner.address.locality === ''
          ) {
            this.form.owner.address.locality = this.contractData.contractInfo.owner.address.locality;
          }

          if (
            this.contractData.contractInfo.address.locality &&
            this.form.address.locality === ''
          ) {
            this.form.address.locality = this.contractData.contractInfo.address.locality;
          }
        }, 500);
      });
    },
    copyAddress(type) {
      const deepClone = obj => JSON.parse(JSON.stringify(obj));

      switch (type) {
        case 'owner-to-address':
          // eslint-disable-next-line
          const address = deepClone(this.form.owner.address);
          this.localityToCopy = address.locality;
          this.form.address = address;
          break;
        default:
          // eslint-disable-next-line
          console.warn('Unknown copyAddress type:', type);
          break;
      }
    },
    handlePersonaTypeSelect(option) {
      this.form.specificBussiness.personaType = option.label;
    },
    handleBusinessDocumentSelect(option) {
      this.form.specificBussiness.documentType = option.label;
    },
    handleDocumentSelectOwner(option) {
      this.documentTypeTitle.owner = ['DNI', 'CIF'].includes(option.label)
        ? 'DNI/CIF'
        : option.label;
    },
    handleDocumentSelectContact(option) {
      this.documentTypeTitle.contact = ['DNI', 'CIF'].includes(option.label) ? 'DNI' : option.label;
    },
    handleDocumentSelectBillingContact(option) {
      this.documentTypeTitle.billingContact = ['DNI', 'CIF'].includes(option.label)
        ? 'DNI/CIF'
        : option.label;
    },
    handleDocumentSelectBillingOther(option) {
      this.documentTypeTitle.billingOther = ['DNI', 'CIF'].includes(option.label)
        ? 'DNI/CIF'
        : option.label;
    },
    modifyUserOptions(acc, option) {
      if (option.descripcion === 'NIF') {
        acc.push({ ...option, descripcion: 'DNI' });
        acc.push({ ...option, descripcion: 'CIF' });
      } else {
        acc.push(option);
      }

      return acc;
    },
    goToContact() {
      const { contactRef } = this.$refs;

      window.scroll({
        top: contactRef.$el.offsetTop - 150,
        behavior: 'smooth'
      });
    },
    handleRadioChange({ value }) {
      this.form.billing.billingOwner = value;
    },
    handleDownload() {
      // this.downloading = true;
      // this.$emit('download-offer', {
      //   offerId: this.formExternalData.offerSelected.id,
      //   stopLoading: () => {
      //     this.downloading = false;
      //   }
      // });
      window.print();
    },
    getFormErrors() {
      const { houseType } = this;
      const {
        differentOwnerAddress,
        owner,
        address,
        billing,
        confirmations,
        contactIsNotOwner,
        contact,
        specificBussiness
      } = this.form;
      const errors = [];
      const isEmpty = v => v === undefined || v === null || v === '';
      const isFalse = v => v === false;
      const checkIBAN = string => IBAN.isValid(string);

      if (isEmpty(owner.documentPics.base64Front) && houseType === 'business')
        errors.push(FormErrors.DOCUMENT_PIC_FRONT_REQUIRED);
      if (isEmpty(owner.documentPics.base64Back) && houseType === 'business')
        errors.push(FormErrors.DOCUMENT_PIC_BACK_REQUIRED);

      if (this.form?.haveVirtualBattery === true && this.bateriaVirtual.anexoBateria) {
        if (
          this.form?.documents?.virtualBattery.fileName === null ||
          this.form?.documents?.virtualBattery.fileName === ''
        ) {
          errors.push(FormErrors.DOCUMENT_VIRTUAL_BATTERY_REQUIRED);
        }
      }

      if (isEmpty(this.form.bateriaVirtual.modo_activacion) && this.form.haveVirtualBattery) {
        errors.push(FormErrors.DOCUMENT_GESTION_BATERIA);
      }

      if (
        isEmpty(this.form.bateriaVirtual.codigo_bateria) &&
        this.form.haveVirtualBattery &&
        this.form.bateriaVirtual.tipo_bateria === 'importar'
      ) {
        errors.push(FormErrors.CODIGO_BATERIA_VIRTUAL);
      }

      if (
        isEmpty(this.form.bateriaVirtual.identificador_asociado) &&
        this.form.haveVirtualBattery &&
        this.form.bateriaVirtual.tipo_bateria === 'importar'
      ) {
        errors.push(FormErrors.IDENTIFICADOR_BATERIA_VIRTUAL);
      }

      // Datos de empresa
      if (houseType === 'business') {
        if (!isValidCif(owner.document)) {
          errors.push(FormErrors.DOCUMENT_INVALID);
        }

        if (isEmpty(owner.document)) {
          errors.push(FormErrors.DOCUMENT_REQUIRED);
        }

        if (isEmpty(owner.documentType)) {
          errors.push(FormErrors.DOCUMENT_TYPE_REQUIRED);
        }

        if (isEmpty(owner.phone)) {
          errors.push(FormErrors.PHONE_REQUIRED);
        }

        if (isEmpty(owner.email)) {
          errors.push(FormErrors.EMAIL_REQUIRED);
        }

        if (this.isInvalidEmail(owner.email)) errors.push(FormErrors.EMAIL_INVALID);

        if (!isEmpty(owner.email) && owner.email !== owner.confirmEmail) {
          errors.push(FormErrors.EMAIL_NOT_EQUAL);
        }

        if (isEmpty(specificBussiness.socialReason)) {
          errors.push(FormErrors.BUSSINESS_SOCIAL_REASON);
        }

        // if (isEmpty(specificBussiness.pics.notarialBase64)) {
        //   errors.push(FormErrors.BUSSINESS_PIC_NOTARIAL);
        // }

        // if (isEmpty(specificBussiness.pics.cifBase64)) {
        //   errors.push(FormErrors.BUSSINESS_PIC_CIF);
        // }

        if (isEmpty(specificBussiness.cnae)) {
          errors.push(FormErrors.BUSSINESS_CNAE);
        }
      }

      if (houseType !== 'business') {
        if (isEmpty(owner.name)) {
          errors.push(FormErrors.NAME_REQUIRED);
        }

        if (isEmpty(owner.pais_procedencia) && owner.documentType === 6) {
          errors.push(FormErrors.PAIS);
        }

        if (isEmpty(owner.lastName)) {
          errors.push(FormErrors.LASTNAME_REQUIRED);
        }

        if (
          !isEmpty(owner.documentType) &&
          !isEmpty(owner.document) &&
          owner.documentType === 4 &&
          !isValidDni(owner.document)
        ) {
          errors.push(FormErrors.DOCUMENT_INVALID);
        }

        if (
          !isEmpty(owner.documentType) &&
          !isEmpty(owner.document) &&
          owner.documentType === 8 &&
          !isValidNie(owner.document)
        ) {
          errors.push(FormErrors.NIE_INVALID);
        }
      }

      if (isEmpty(owner.document)) {
        errors.push(FormErrors.DOCUMENT_REQUIRED);
      }

      if (isEmpty(owner.documentType)) {
        errors.push(FormErrors.DOCUMENT_TYPE_REQUIRED);
      }

      if (isEmpty(owner.phone)) {
        errors.push(FormErrors.PHONE_REQUIRED);
      }

      if (isEmpty(owner.email)) {
        errors.push(FormErrors.EMAIL_REQUIRED);
      }

      if (this.isInvalidEmail(owner.email)) errors.push(FormErrors.EMAIL_INVALID);

      if (!isEmpty(owner.email) && owner.email !== owner.confirmEmail) {
        errors.push(FormErrors.EMAIL_NOT_EQUAL);
      }

      // Comprobar los datos de "El titular es otra persona" si esta activado
      if (contactIsNotOwner || houseType === 'business') {
        if (isEmpty(contact.documentType)) errors.push(FormErrors.CONTACT_DOCUMENT_TYPE_REQUIRED);
        if (isEmpty(contact.document)) errors.push(FormErrors.CONTACT_DOCUMENT_REQUIRED);

        if (
          !isEmpty(contact.documentType) &&
          !isEmpty(contact.document) &&
          contact.documentType === 4 &&
          !isValidDni(contact.document)
        ) {
          errors.push(FormErrors.CONTACT_DOCUMENT_DNI_INVALID);
        }

        if (
          !isEmpty(contact.documentType) &&
          !isEmpty(contact.document) &&
          contact.documentType === 8 &&
          !isValidNie(contact.document)
        ) {
          errors.push(FormErrors.CONTACT_DOCUMENT_NIE_INVALID);
        }

        if (isEmpty(contact.name)) errors.push(FormErrors.CONTACT_NAME_REQUIRED);
        if (isEmpty(contact.lastName)) errors.push(FormErrors.CONTACT_LASTNAME_REQUIRED);
        if (isEmpty(contact.phone)) errors.push(FormErrors.CONTACT_PHONE_REQUIRED);
        if (isEmpty(contact.email)) errors.push(FormErrors.CONTACT_EMAIL_REQUIRED);
        if (this.isInvalidEmail(contact.email)) errors.push(FormErrors.CONTACT_EMAIL_INVALID);
        if (!isEmpty(contact.email) && contact.email !== contact.confirmEmail)
          errors.push(FormErrors.CONTACT_EMAIL_NOT_EQUAL);
      }

      // Comprobar por separado la direccion (si se activa)
      if (differentOwnerAddress) {
        if (isEmpty(address.postalCode)) errors.push(FormErrors.POSTALCODE_REQUIRED);
        if (isEmpty(address.province)) errors.push(FormErrors.PROVINCE_REQUIRED);
        if (isEmpty(address.locality)) errors.push(FormErrors.LOCALITY_REQUIRED);
        if (isEmpty(address.viaType)) errors.push(FormErrors.VIA_TYPE_REQUIRED);
        if (isEmpty(address.line1)) errors.push(FormErrors.ADDRESS_REQUIRED);
        if (isEmpty(address.number)) errors.push(FormErrors.ADDRESS_NUMBER_REQUIRED);
      }

      // Dirección del titular
      if (isEmpty(owner.address.postalCode))
        errors.push(FormErrors.OWNER_ADDRESS_POSTALCODE_REQUIRED);
      if (isEmpty(owner.address.province)) errors.push(FormErrors.OWNER_ADDRESS_PROVINCE_REQUIRED);
      if (isEmpty(owner.address.locality)) errors.push(FormErrors.OWNER_ADDRESS_LOCALITY_REQUIRED);
      if (isEmpty(owner.address.viaType)) errors.push(FormErrors.OWNER_ADDRESS_VIA_TYPE_REQUIRED);
      if (isEmpty(owner.address.line1)) errors.push(FormErrors.OWNER_ADDRESS_ADDRESS_REQUIRED);
      if (isEmpty(owner.address.number))
        errors.push(FormErrors.OWNER_ADDRESS_ADDRESS_NUMBER_REQUIRED);

      // Facturación
      if (isEmpty(billing.iban)) errors.push(FormErrors.IBAN_REQUIRED);
      if (!isEmpty(billing.iban) && !checkIBAN(billing.iban)) errors.push(FormErrors.IBAN_INVALID);

      if (isEmpty(billing.bicSwiftCode)) errors.push(FormErrors.BICSWIFT_REQUIRED);

      if (billing.billingOwner === 'other') {
        if (isEmpty(billing.owner.documentType))
          errors.push(FormErrors.BILLING_OWNER_DOCUMENT_TYPE_REQUIRED);
        if (isEmpty(billing.owner.document))
          errors.push(FormErrors.BILLING_OWNER_DOCUMENT_REQUIRED);
        if (
          !isEmpty(billing.owner.documentType) &&
          !isEmpty(billing.owner.document) &&
          billing.owner.documentType === 4 &&
          !isValidDni(billing.owner.document) &&
          !isValidCif(billing.owner.document)
        )
          errors.push(FormErrors.BILLING_OWNER_DOCUMENT_INVALID);
        if (
          !isEmpty(billing.owner.documentType) &&
          !isEmpty(billing.owner.document) &&
          billing.owner.documentType === 8 &&
          !isValidNie(billing.owner.document)
        )
          errors.push(FormErrors.BILLING_OWNER_NIE_INVALID);
        if (isEmpty(billing.owner.name)) errors.push(FormErrors.BILLING_OWNER_NAME_REQUIRED);
        if (isEmpty(billing.owner.lastName))
          errors.push(FormErrors.BILLING_OWNER_LASTNAME_REQUIRED);
      }

      // Confirmaciones
      // if (isFalse(confirmations.commercialComms)) errors.push(FormErrors.COMMERCIAL_COMMS_REQUIRED);
      if (isFalse(confirmations.marketerStartServiceBeforeWithdrawal))
        errors.push(FormErrors.TEMS_PRIVACY_REQUIRED);
      if (isFalse(confirmations.hiringTermsPolicyPrivacy))
        errors.push(FormErrors.MARKETER_START_SERVICE_REQUIRED(this.marketerName));

      return errors;
    },
    async handleDocumentVirtualBattery(file) {
      this.form.documents.virtualBattery.fileName = await this.submitFile(file);
    },
    async handleDocumentPicFront(file) {
      this.form.owner.documentPics.base64Front = await this.submitFile(file);
    },
    async handleDocumentPicBack(file) {
      this.form.owner.documentPics.base64Back = await this.submitFile(file);
    },
    async handlePicNotarial(file) {
      this.form.specificBussiness.pics.notarialBase64 = await this.submitFile(file);
    },
    async handlePicCif(file) {
      this.form.specificBussiness.pics.cifBase64 = await this.submitFile(file);
    },
    async handleBill(file) {
      this.form.owner.documentPics.bill = await this.submitFile(file);
    },

    async submitFile(file) {
      const formData = new FormData();
      formData.append('file', file);

      const response = await uploadFile(this.leadId, formData);

      return response.data.fileName;
    },
    share() {
      const url = window.location.href;
      const data = {
        title: 'Oferta de suministro',
        text: 'Oferta de suministro',
        url
      };

      if (navigator.share !== undefined) {
        navigator.share(data);
      } else {
        copy(`${data.url}`, {
          message: 'Pulsa #{key} para copiar'
        });
        this.$notify.success('¡Oferta copiada en el portapapeles!');
      }
    },
    getBody() {
      const cloned = clone(this.form);

      const fillSameKeys = (ref, target) => {
        Object.keys(ref).forEach(key => {
          // eslint-disable-next-line
          if (target.hasOwnProperty(key)) target[key] = ref[key];
        });
      };

      if (cloned.billing.billingOwner === 'owner') {
        fillSameKeys(cloned.owner, cloned.billing.owner);
      }

      if (cloned.billing.billingOwner === 'contact') {
        cloned.billing.owner.name = this.form.contact.name;
        cloned.billing.owner.lastName = this.form.contact.lastName;
        cloned.billing.owner.secondLastName = this.form.contact.secondLastName;
        cloned.billing.owner.documentType = this.form.contact.documentType;
        cloned.billing.owner.document = this.form.contact.document;
      }

      if (!cloned.differentOwnerAddress) {
        cloned.address = clone(cloned.owner.address);
      }

      if (cloned.owner.documentType === 4) {
        cloned.owner.pais_procedencia = 'ES';
      }

      cloned.owner.personType = 1;

      if (this.houseType === 'business') {
        cloned.contactIsNotOwner = true;
        cloned.owner.personType = 2;
      }

      return cloned;
    },

    async calcularScoring() {
      if (this.allSteps[1].inputData.house_type !== 'business') return;
      const allFeesEnergy = this.allSteps[4].inputData.selectedRates.map(element => {
        if (element.id === this.formExternalData.offerSelected.id) {
          return Object.values(element.values.feesEnergy.selected);
        }
      });

      let maxEnergyFee = Math.max(...allFeesEnergy.flat().filter(Number));

      if (this.checkUndefined(maxEnergyFee) === '' || maxEnergyFee === -Infinity) {
        maxEnergyFee = 0;
      }

      const rateIds = [];

      this.allSteps[4].inputData.selectedRates.forEach(rate => {
        if (rate.id === this.allSteps[5].inputData.offerSelected.id) {
          rateIds.push({
            id_tarifa: rate.id,
            potencia_maxima: Math.max(...Object.values(rate.hiredPower).filter(Number)),
            fee_energia_maximo:
              Math.max(...Object.values(rate.values.feesEnergy.selected).filter(Number)) ===
              -Infinity
                ? 0
                : Math.max(...Object.values(rate.values.feesEnergy.selected).filter(Number)) / 1000
          });
        }
      });

      const data = {
        cups: this.allSteps[2].inputData.data,
        rateIds,
        documentNumber: this.form.owner.document,
        anualVolumen: this.allSteps[4].inputData.consumoAnualEstimado
      };

      await apis
        .calcularScoring(data)
        .then(response => {
          if ('status_scoring' in response.data) {
            if (response.data.status_scoring === 'desactivado') {
              this.scoringAprove = false;
              return;
            }

            if (Object.values(response.data.status_scoring).includes('denegado')) {
              this.$notify.error('Scoring denegado');
              this.buttonMessage = 'Denegado';
              this.buttonState = 'denegado';
              this.scoringAprove = false;
              return;
            }
            const estado = Object.values(response.data.status_scoring);
            // eslint-disable-next-line prefer-destructuring
            this.buttonMessage = estado[0].charAt(0).toUpperCase() + estado[0].slice(1);
            // eslint-disable-next-line prefer-destructuring
            this.buttonState = estado[0];
            this.scoringAprove = true;
            return;
          }
          this.scoringAprove = false;
        })
        .catch(() => {
          this.$notify.error('No se ha podido calcular el scoring de las tarifas seleccionadas.');
        });
    },
    async onContinue() {
      this.triedContinue = true;
      const errors = this.getFormErrors();

      if (
        this.form.bateriaVirtual.tipo_bateria === 'importar' &&
        !this.bateriaVirtualValidada &&
        this.form.haveVirtualBattery
      ) {
        this.$notify.error('Debe validar la batería virtual antes de continuar');
        if (this.$refs.bottomSheetCustomRate) this.$refs.bottomSheetCustomRate.resetLoading();
        this.formErrors = errors;
        return;
      }

      if (this.scoringActivo && this.userDocumentTypesComputed.some(d => d.label === 'CIF')) {
        await this.calcularScoring();
        if (!this.scoringAprove) return;
      }

      if (errors.length === 0) {
        this.loading = true;
        const body = this.getBody();
        this.$emit('continue', body);
        return;
      }

      if (this.$refs.bottomSheetCustomRate) this.$refs.bottomSheetCustomRate.resetLoading();
      this.formErrors = errors;
      this.scrollToFirstError();
    },
    async getFirstError() {
      return new Promise(resolve => {
        const check = () => {
          // eslint-disable-next-line
          document.querySelector('.invalid')
            ? resolve(document.querySelector('.invalid'))
            : window.requestAnimationFrame(check);
        };
        check();
      });
    },
    async scrollToFirstError() {
      const firstError = document.querySelector('.invalid') || (await this.getFirstError());
      window.scroll({
        top: firstError.offsetTop - 150,
        behavior: 'smooth'
      });
    }
  }
};
